type Options = {
  leading?: boolean;
  // Currently, there is not a need to support the following options:
  // trailing?: boolean;
  // maxWait?: number;
};

const debounce = (fn: Function, delay: number = 0, options: Options = {}) => {
  let timeout;
  const leading = options.leading ?? false;
  let called = false;

  const debounced = (...args) => {
    if (leading && !called) {
      fn(...args);
      called = true;

      return;
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
      called = false;
    }, delay);
  };

  debounced.cancel = () => {
    clearTimeout(timeout);
    called = false;
  };

  return debounced;
};

export { debounce };
